import { render, staticRenderFns } from "./grid-square-arrangement.vue?vue&type=template&id=aec3a574&scoped=true&"
import script from "./grid-square-arrangement.vue?vue&type=script&lang=js&"
export * from "./grid-square-arrangement.vue?vue&type=script&lang=js&"
import style0 from "./grid-square-arrangement.vue?vue&type=style&index=0&id=aec3a574&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aec3a574",
  null
  
)

export default component.exports